import React, { useEffect, useState } from "react";
import {
  Box,
  Title,
  SubTitle,
  Text,
  Button,
  Space,
  lightblue,
  blue,
  LeadingText,
  LeadingTextBlock,
  lightyellow,
} from "../theme/theme";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import Spinner from "../components/spinner";
import { Container, Grid, useTheme, useMediaQuery } from "@mui/material";

import BlockContent from "@sanity/block-content-to-react";

import settings from "../settings.json";
import { Link } from "react-router-dom";
import Landing from "./Landing";

// TODO: implement

export const OnsVerhaalContent = () => {
  const verhaal = useSelector((state) => state.data.verhaal);
  const home = useSelector((state) => state.data.home);
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });

  // console.log(verhaal);

  if (!verhaal) {
    return null;
  }

  return (
    <div style={{ background: lightblue }}>
      <Container>
        {home && home.intro ? null : (
          <>
            <br />
            <br />
            <br />
            <br />
            <br />
          </>
        )}
        <br />
        <br />
        <br />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3} lg={3} />
          <Grid item xs={12} sm={12} md={9} lg={7}>
            <SubTitle color={blue}>
              <div style={{ textTransform: "none" }}>
                <BlockContent blocks={home.introtext} />
              </div>
            </SubTitle>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* Adjust the grid item sizes as necessary for your design */}
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Title color={blue}>Ons Verhaal</Title>
            {verhaal && verhaal.image && isDesktop ? (
              <div>
                <br />
                <br />
                <img
                  src={verhaal.image}
                  style={{ width: "100%", paddingRight: "15px" }}
                />
              </div>
            ) : null}

            {verhaal && verhaal.mobiel && !isDesktop ? (
              <div>
                <br />
                <img src={verhaal.mobiel} style={{ width: "100%" }} />
              </div>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={7}>
            <LeadingTextBlock color={blue} description={verhaal.description} />
          </Grid>
        </Grid>
      </Container>
      <br />
      <br />
    </div>
  );
};

const Onsverhaal = () => {
  const landing = useSelector((state) => state.data.landing);
  const verhaal = useSelector((state) => state.data.verhaal);

  const Meta = (
    <MetaTags>
      <title>{"MEEtec verhaal"}</title>
      <meta
        name="description"
        content={"Mechelse Energie Technieken - Verhaal"}
      />
      <meta property="og:title" content={"MEEtec verhaal"} />
    </MetaTags>
  );

  if (!verhaal) {
    return (
      <>
        {Meta}
        <Spinner />
      </>
    );
  } else {
    return (
      <>
        {Meta}
        <OnsVerhaalContent />
      </>
    );
  }
};

export default Onsverhaal;
