import React from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import BlockContent from "@sanity/block-content-to-react";
import Spinner from "./spinner";
import { Grid } from "@mui/material";
import { Icon, Title, Text, mustardyellow, lightyellow } from "../theme/theme";
import { BottomNavigation } from "@mui/material";
import { CiInstagram } from "react-icons/ci";
import { useMediaQuery, useTheme } from "@mui/material";
import Partners from "./partners";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const home = useSelector((state) => state.data.home);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();

  const isHome = location.pathname === "/";

  if (!home) {
    return <Spinner />;
  }

  return (
    <>
      {isHome && (
        <div style={{ width: "100%", backgroundColor: lightyellow }}>
          <Partners />
          <br />
        </div>
      )}

      <footer
        style={{
          flexShrink: 0,
          width: "100%",
          background: "#1B3347",
          color: "#D1B221",
        }}
      >
        <Box
          sx={{ display: "flex" }}
          style={{
            textAlign: "left",
            paddingTop: "20px",
            paddingBottom: "20px",
            alignItem: "center",
            justifyContent: "center",
          }}
        >
          <Grid container spacing={2}>
            {isSmallScreen ? (
              <Grid item xs={12} sm={12} md={false}>
                <div style={{ textAlign: "center" }}>
                  <Text>
                    <BlockContent blocks={home.footer} />
                  </Text>
                </div>
              </Grid>
            ) : null}

            <Grid item xs={3} sm={2}>
              &nbsp;
            </Grid>

            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              style={{ transform: "translateY(14px)" }}
            >
              <div style={{ width: "100%", maxWidth: "100px" }}>
                <Icon filter={"filter-mustardyellow"} />
              </div>
            </Grid>

            <Grid item xs={1}>
              &nbsp;
            </Grid>

            {!isSmallScreen ? (
              <Grid item xs={false} sm={false} md={4}>
                <div style={{ paddingRight: "50px" }}>
                  <Text>
                    <BlockContent blocks={home.footer} />
                  </Text>
                </div>
              </Grid>
            ) : null}

            <Grid item xs={false} sm={false} md={2}>
              <a href="https://www.instagram.com/meetec/" target="_blank">
                <CiInstagram
                  color={mustardyellow}
                  style={{ fontSize: "2.5rem", marginTop: "10px" }}
                  className="interacter"
                />
              </a>
            </Grid>

            <Grid item xs={false} sm={false} md={1}>
              &nbsp;
            </Grid>
          </Grid>
        </Box>
      </footer>
    </>
  );
};

export default Footer;
