import React from "react";
import { useSelector } from "react-redux";
import { Container, Typography, Avatar, Paper } from "@mui/material";
import Slider from "react-slick";
import { Box, blue, lightblue } from "../theme/theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = () => {
  const testimonials = useSelector((state) => state.data.home?.testimonials);

  if (!testimonials) {
    return null; // or Spinner component
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    responsive: [
      {
        breakpoint: 960,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
  };

  return (
    <div style={{ padding: "40px 0" }}>
      <Container>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <Box key={index} style={{ padding: "0 0px" }}>
              <Paper
                elevation={3}
                style={{
                  padding: "20px",
                  height: "100%",
                  backgroundColor: lightblue,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600, color: blue }}
                  >
                    {testimonial.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ fontStyle: "italic", marginTop: "10px" }}
                  >
                    "{testimonial.testimonialText}"
                  </Typography>
                </Box>
                <Avatar
                  src={testimonial.picture}
                  alt={testimonial.name}
                  sx={{ width: 80, height: 80, marginTop: "15px" }}
                />
              </Paper>
            </Box>
          ))}
        </Slider>
      </Container>
    </div>
  );
};

export default Testimonials;
