import React from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Link } from "@mui/material";
import { blue, SubTitle, Title } from "../theme/theme";

const Partners = () => {
  const partners = useSelector((state) => state.data.home?.partners);

  if (!partners || partners.length === 0) return null;

  return (
    <>
      <div style={{ textAlign: "center", paddingBottom: "10px" }}>
        <Title color={blue}>Onze Partners</Title>
      </div>
      <Box sx={{ py: 4, display: "flex", justifyContent: "center" }}>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          {partners.map((partner, index) => (
            <Grid item key={index} xs={6} sm={4} md={2}>
              <Link
                href={partner.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box
                  component="img"
                  src={partner.logo}
                  alt={`Partner ${index + 1}`}
                  sx={{
                    width: "100%",
                    maxHeight: 80,
                    objectFit: "contain",
                    display: "block",
                    margin: "0 auto",
                  }}
                />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default Partners;
