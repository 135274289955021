import React from "react";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import Spinner from "../components/spinner";
import { useLocation } from "react-router-dom";

import {
  Box,
  Title,
  LeadingTextBlock,
  blue,
  lightblue,
  ResponsiveImage,
  lightyellow,
} from "../theme/theme";
import { Container, Grid, Typography } from "@mui/material";
import Testimonials from "../components/testimonials";

const Team = () => {
  const teamImage = useSelector((state) => state.data.home?.teamImage);
  const location = useLocation();

  const isHome = location.pathname === "/";
  const isTeam = location.pathname === "/team";
  // const teamDescription = "Meet the creative minds behind our success.";

  const Meta = (
    <MetaTags>
      <title>{"Our Team - MEEtec"}</title>
      <meta name="description" content={"Het MEEtec team"} />
      <meta property="og:title" content={"Ons Team"} />
    </MetaTags>
  );

  if (!teamImage) {
    return (
      <>
        {Meta}
        <Spinner />
      </>
    );
  }

  return (
    <>
      {Meta}
      <div style={{ minHeight: "100vh", background: lightyellow }}>
        <Container>
          <br />
          <br />
          {isTeam && (
            <div>
              <br />
              <br />
              <br />
              <br />
            </div>
          )}

          {!isTeam && <Testimonials />}

          <Grid container spacing={2} alignItems="center">
            {/* Title Section */}
            {/* <Grid item xs={12} sm={12} md={4} lg={4}>
              <Title color={blue}>Ons Team</Title> */}
            {/* <LeadingTextBlock color={blue} description={teamDescription} /> */}
            {/* </Grid> */}

            {/* Image Section */}
            <Grid item md={2} />
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Box style={{ textAlign: "center" }}>
                {isTeam && (
                  <Typography
                    style={{
                      fontWeight: 800,
                      fontSize: "3.8rem",
                      paddingTop: "20px",
                      lineHeight: 1.0,
                      textTransform: "uppercase",
                      color: blue,
                      textAlign: "center",
                    }}
                  >
                    Ons Team
                    <br />
                    <br />
                  </Typography>
                )}

                <ResponsiveImage src={teamImage} alt="Team" />
              </Box>
            </Grid>
          </Grid>
          <br />
          <br />
        </Container>
      </div>
    </>
  );
};

export default Team;
