import React, { useEffect, useState } from "react";
import {
  Box,
  Title,
  SubTitle,
  Text,
  Button,
  Space,
  LeadingTextBlock,
  blue,
  lightyellow,
} from "../theme/theme";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import Spinner from "../components/spinner";
import { Container, Grid } from "@mui/material";
import BlockContent from "@sanity/block-content-to-react";

import settings from "../settings.json";
import { Link } from "react-router-dom";
import Landing from "./Landing";
import { OnsVerhaalContent } from "./Onsverhaal";
import { TechniekenContent } from "./Technieken";
import { PremiesContent } from "./Premies";
import Contact, { ContactContent } from "./Contact";
import Team from "./Team";

// TODO: implement

const Home = () => {
  const [landing, home, verhaal, contact, premie, projects, technieken] =
    useSelector((state) => [
      state.data.landing,
      state.data.home,
      state.data.verhaal,
      state.data.contact,
      state.data.premie,
      state.data.projects,
      state.data.technieken,
    ]);

  const teamImage = useSelector((state) => state.data.home?.teamImage);

  if (
    !landing ||
    !home ||
    !verhaal ||
    !contact ||
    !premie ||
    !projects ||
    !technieken
  ) {
    return null;
  }

  const Meta = (
    <MetaTags>
      <title>{"MEEtec home"}</title>
      <meta name="description" content={"Mechelse Energie Technieken - Home"} />
      <meta property="og:title" content={"MEEtec home"} />
    </MetaTags>
  );

  return (
    <>
      {Meta}
      {home && home.intro ? (
        <div style={{ width: "100% !important;" }}>
          <img src={home.intro} style={{ width: "100%" }} />
        </div>
      ) : null}
      {/* {home && home.introtext ? (
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

          <Container>
            <LeadingTextBlock
              color={lightyellow}
              description={home.introtext}
            />
          </Container>
        </div>
      ) : null} */}
      <OnsVerhaalContent />
      <TechniekenContent />
      <ContactContent />
      <PremiesContent />
      <Team />
    </>
  );
};

export default Home;
